<!-- eslint-disable vue/no-template-key -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div v-if="!isLoading">
    <v-simple-table
      class="purchased-items-table"
      dense
      style="background-color: #fff;"
    >
      <template v-slot:default>
        <tbody>
          <fragment
            v-for="(item, indItem) in hotel.proveedores"
            :key="indItem"
          >
            <tr class="bgGray">
              <td colspan="11">
                <span class="d-flex flex-row">
                  <v-checkbox
                    v-model="item.check"
                    hide-details
                    class="my-0"
                  ></v-checkbox>
                  <span class="mt-2">
                    {{ `Proveedor #${indItem + 1}: ${item.proveedor.name}` }}
                  </span>
                </span>
              </td>
              <td
                class="text-right"
                width="10px"
              >
                <v-btn
                  icon
                  @click="showItem(indItem)"
                >
                  <v-icon>
                    {{ showRow && indItem === indexRow ? icons.mdiMenuDown : icons.mdiMenuRight }}
                  </v-icon>
                </v-btn>
              </td>
            </tr>

            <template v-if="showRow && indItem === indexRow">
              <tr class="bgGray">
                <td class="text-uppercase">
                  ID
                </td>
                <td class="text-uppercase">
                  {{ $t('lbl.room') }}
                </td>
                <td
                  class="text-uppercase text-left"
                  width="80px"
                >
                  {{ $t('lbl.oferta') }}
                </td>
                <td width="10px"></td>
                <td class="text-uppercase">
                  {{ $t('lbl.from') }}
                </td>
                <td class="text-uppercase">
                  {{ $t('lbl.to') }}
                </td>
                <td
                  class="text-uppercase"
                  width="50px"
                >
                  {{ $t('lbl.plan') }}
                </td>
                <td
                  class="text-uppercase"
                  width="50px"
                >
                  SGL
                </td>
                <td
                  class="text-uppercase"
                  width="50px"
                >
                  DBL
                </td>
                <td
                  class="text-uppercase"
                  width="50px"
                >
                  TPL
                </td>
                <td
                  class="text-uppercase"
                  width="50px"
                >
                  MNR
                </td>
              </tr>
              <template v-for="room in item.rooms">
                <template v-for="type_offert in room.type_offert">
                  <template v-for="(season, indS) in type_offert.season">
                    <tr
                      :key="indS"
                      style="border-collapse: collapse;"
                    >
                      <td
                        v-if="indS === 0"
                        :rowspan="room.allRows"
                        class="verticalAlign"
                      >
                        {{ room.id }}
                      </td>
                      <td
                        v-if="indS === 0"
                        :rowspan="room.allRows"
                        class="verticalAlign"
                      >
                        {{ room.room.name }}<br />
                        <b>EDAD: </b>{{ room.edad_min }} <b class="ml-5">NOCHES: </b>{{ room.days }}
                        <b class="ml-5">RELEASE: </b>{{ room.release }}
                      </td>
                      <td
                        v-if="indS === 0"
                        :rowspan="type_offert.season.length"
                        class="verticalAlign"
                      >
                        {{ type_offert.type }}
                      </td>
                      <td class="tdBorder">
                        <b>TW:</b><br />
                        <b>BW:</b>
                      </td>
                      <td class="tdBorder">
                        {{ $moment(season.from).format('MMM D, YYYY') }} <br />
                        {{ $moment(type_offert.bwFrom).format('MMM D, YYYY') }}
                      </td>
                      <td class="tdBorder">
                        {{ $moment(season.to).format('MMM D, YYYY') }}<br />
                        {{ $moment(type_offert.bwTo).format('MMM D, YYYY') }}
                      </td>
                      <td class="tdBorder">
                        {{ season.plan }}
                      </td>
                      <td class="tdBorder">
                        <span v-if="season.markups">
                          <span v-if="season.markups.is_pc">
                            {{
                              (parseFloat(season.sgl) + (parseFloat(season.sgl) * season.markups.markup) / 100)
                                | currency
                            }}
                          </span>
                          <span v-else>
                            {{ (parseFloat(season.sgl) + season.markups.markup) | currency }}
                          </span>
                        </span>
                      </td>
                      <td class="tdBorder">
                        <span v-if="season.markups">
                          <span v-if="season.markups.is_pc">
                            {{
                              (parseFloat(season.dbl) + (parseFloat(season.dbl) * season.markups.markup) / 100)
                                | currency
                            }}
                          </span>
                          <span v-else>
                            {{ (parseFloat(season.dbl) + season.markups.markup) | currency }}
                          </span>
                        </span>
                      </td>
                      <td class="tdBorder">
                        <span v-if="season.markups">
                          <span v-if="season.markups.is_pc">
                            {{
                              (parseFloat(season.tpl) + (parseFloat(season.tpl) * season.markups.markup) / 100)
                                | currency
                            }}
                          </span>
                          <span v-else>
                            {{ (parseFloat(season.tpl) + season.markups.markup) | currency }}
                          </span>
                        </span>
                      </td>
                      <td class="tdBorder">
                        <span v-if="season.markups">
                          <span v-if="season.markups.is_pc">
                            {{
                              (parseFloat(season.mnr) + (parseFloat(season.mnr) * season.markups.markup) / 100)
                                | currency
                            }}
                          </span>
                          <span v-else>
                            {{ (parseFloat(season.mnr) + season.markups.markup) | currency }}
                          </span>
                        </span>
                      </td>
                    </tr>
                  </template>
                </template>
              </template>

              <template v-if="item.suplements.length > 0">
                <tr class="bgGray">
                  <td
                    class="text-uppercase"
                    colspan="2"
                  >
                    {{ $t('lbl.suplementsCost') }}
                  </td>
                  <td
                    class="text-uppercase"
                    colspan="2"
                  >
                    {{ $t('lbl.rooms') }}
                  </td>
                  <td class="text-uppercase">
                    {{ $t('lbl.from') }}
                  </td>
                  <td class="text-uppercase">
                    {{ $t('lbl.to') }}
                  </td>
                  <td class="text-uppercase">
                    {{ $t('lbl.plan') }}
                  </td>
                  <td class="text-uppercase">
                    ADL
                  </td>
                  <td class="text-uppercase">
                    MNR
                  </td>
                  <td class="text-uppercase">
                    $/U
                  </td>
                  <td class="text-uppercase">
                    {{ $t('lbl.tipo') }}
                  </td>
                </tr>
                <tr
                  v-for="(suplement, indSup) in item.suplements"
                  :key="indSup"
                >
                  <td colspan="2">
                    {{ suplement.suplemento.name }}
                  </td>
                  <td colspan="2">
                    <span
                      v-for="(supR, indSSS) in suplement.rooms"
                      :key="indSSS"
                    >
                      {{ `${supR} ${indSSS !== suplement.rooms.length - 1 ? ', ' : ''}` }}
                    </span>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </template>

              <template v-if="item.doc_require.length > 0">
                <tr class="bgGray">
                  <td
                    colspan="11"
                    class="text-uppercase"
                  >
                    {{ $t('lbl.docRequired') }}
                  </td>
                </tr>
                <tr>
                  <td colspan="11">
                    <ol>
                      <li
                        v-for="(doc, indDoc) in item.doc_require"
                        :key="indDoc"
                      >
                        {{ doc }}
                      </li>
                    </ol>
                  </td>
                </tr>
              </template>

              <template v-if="item.polices.length > 0">
                <tr class="bgGray">
                  <td
                    colspan="11"
                    class="text-uppercase"
                  >
                    {{ $t('lbl.policesCancele') }}
                  </td>
                </tr>
                <tr>
                  <td colspan="11">
                    <ol>
                      <li
                        v-for="(doc, indPol) in item.polices"
                        :key="indPol"
                      >
                        {{ doc }}
                      </li>
                    </ol>
                  </td>
                </tr>
              </template>

              <template v-if="item.terms.length > 0">
                <tr class="bgGray">
                  <td
                    colspan="11"
                    class="text-uppercase"
                  >
                    {{ $t('lbl.termsConditions') }}
                  </td>
                </tr>
                <tr>
                  <td colspan="11">
                    <ol>
                      <li
                        v-for="(doc, indTer) in item.terms"
                        :key="indTer"
                      >
                        {{ doc }}
                      </li>
                    </ol>
                  </td>
                </tr>
              </template>

              <template v-if="item.terms.length > 0">
                <tr class="bgGray">
                  <td
                    colspan="11"
                    class="text-uppercase"
                  >
                    {{ $t('lbl.notes') }}
                  </td>
                </tr>
                <tr>
                  <td colspan="11">
                    <ol>
                      <li
                        v-for="(doc, indNot) in item.notes"
                        :key="indNot"
                      >
                        {{ doc }}
                      </li>
                    </ol>
                  </td>
                </tr>
              </template>
            </template>

            <!--<template v-if="showRow && indItem === indexRow">
              <tr class="bgGray">
                <td class="text-uppercase">
                  {{ $t('lbl.room') }}
                </td>
                <td class="text-uppercase">
                  {{ $t('lbl.oferta') }}
                </td>
                <td class="text-uppercase">
                  {{ `TW ${$t('lbl.from')}` }}
                </td>
                <td class="text-uppercase">
                  {{ `TW ${$t('lbl.to')}` }}
                </td>
                <td class="text-uppercase">
                  {{ `BW ${$t('lbl.from')}` }}
                </td>
                <td class="text-uppercase">
                  {{ `BW ${$t('lbl.to')}` }}
                </td>
                <td class="text-uppercase">
                  {{ $t('lbl.plan') }}
                </td>
                <td class="text-uppercase">
                  {{ $t('lbl.nights') }}
                </td>
                <td class="text-uppercase">
                  SGL
                </td>
                <td class="text-uppercase">
                  DBL
                </td>
                <td class="text-uppercase">
                  TPL
                </td>
                <td class="text-uppercase">
                  MNR
                </td>
              </tr>
              <template v-for="room in item.rooms">
                <tr
                  v-for="(typeOff, indOff) in room.room_data.type_offert"
                  :key="indOff"
                >
                  <td
                    v-if="indOff === 0"
                    :rowspan="room.room_data.type_offert.length"
                  >
                    {{ room.room_data.name }}
                  </td>
                  <td>{{ typeOff.type }}</td>
                  <td>{{ typeOff.twFrom }}</td>
                  <td>{{ typeOff.twTo }}</td>
                  <td>{{ typeOff.bwFrom }}</td>
                  <td>{{ typeOff.bwTo }}</td>
                  <td>{{ typeOff.plan }}</td>
                  <td style="text-align: center;">
                    {{ typeOff.nights }}
                  </td>
                  <td>{{ typeOff.sgl | currency('') }}</td>
                  <td>{{ typeOff.dbl | currency('') }}</td>
                  <td>{{ typeOff.tpl | currency('') }}</td>
                  <td>{{ typeOff.mnr | currency('') }}</td>
                </tr>
              </template>

              <template v-if="item.suplements.length > 0">
                <tr class="bgGray">
                  <td
                    class="text-uppercase"
                    colspan="2"
                  >
                    {{ $t('menu.suplementos') }}
                  </td>
                  <td
                    class="text-uppercase"
                    colspan="2"
                  >
                    {{ $t('lbl.tipo') }}
                  </td>
                  <td class="text-uppercase">
                    {{ $t('lbl.from') }}
                  </td>
                  <td class="text-uppercase">
                    {{ $t('lbl.to') }}
                  </td>
                  <td
                    class="text-uppercase"
                    colspan="2"
                  >
                    {{ $t('lbl.plan') }}
                  </td>
                  <td
                    class="text-uppercase"
                    colspan="3"
                  >
                    ADL
                  </td>
                  <td class="text-uppercase">
                    MNR
                  </td>
                </tr>
                <tr
                  v-for="(suplement, indSup) in item.suplements"
                  :key="indSup"
                >
                  <td colspan="2">
                    {{ suplement.name }}
                  </td>
                  <td colspan="2">
                    {{ suplement.required ? $t('lbl.obligatorio') : $t('lbl.optional') }}
                  </td>
                  <td></td>
                  <td></td>
                  <td colspan="2"></td>
                  <td colspan="3"></td>
                  <td></td>
                </tr>
              </template>
            </template>-->
          </fragment>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyePlus,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiFormatListText,
  mdiEraserVariant,
  mdiArrowUp,
  mdiArrowDown,
  mdiMenuDown,
  mdiMenuRight,
  mdiEyeCheck,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    hotel: {
      type: Object,
    },
  },

  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      search: '',
      items: [],
      pagination: {
        current: 1,
        total: 0,
      },
      totalItems: 0,
      totalF: 0,

      showRowProduct: false,
      showRowProveedor: false,
      showRowContrate: false,
      showRowHotel: false,
      showRowRoom: false,
      showRowTemporada: false,
      showRowDate: false,

      // itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyePlus,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiFormatListText,
        mdiEraserVariant,
        mdiArrowUp,
        mdiArrowDown,
        mdiMenuDown,
        mdiMenuRight,
        mdiEyeCheck,
      },
      isDialogVisible: false,
      itemsOrigen: [],
      searchOrigen: null,
      itemsDestino: [],
      searchDestino: null,
      loading: false,

      showRow: false,
      indexRow: -1,

      itemsDestinos: [],
      searchDestinos: null,
      noResultDestinos: false,
      itemsOperador: [],
      searchOperador: null,

      minDate: new Date(),
      menuFrom: false,
      menuTo: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      itemsList: [],
    }
  },
  computed: {
    ...mapState({
      itemsTarifarios: state => state.app.itemsTarifarios,
    }),
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false
    }, 100)
  },
  methods: {
    ...mapMutations(['updateMarkupsList', 'deleteMarkupsList', 'updateItemsTarifarios']),
    showItem(index) {
      if (this.indexRow === index) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.indexRow = index
    },
  },
}
</script>

<style lang="scss">
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}

.bgGray {
  background-color: #eee;
}

tbody {
  tr:hover {
    background-color: #eee !important;
  }
}
</style>
