<template>
  <v-row>
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.booking')} / ${$t('menu.tarifarios')}`">
        <TarifariosList />
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'

import TarifariosList from './tablas/TarifariosList.vue'

export default {
  components: {
    AppCardCode,
    TarifariosList,
  },
}
</script>
