<template>
  <div v-if="!isLoading">
    <v-card-text>
      <v-row v-if="!$store.state.app.onlyShow">
        <v-col
          cols="12"
          md="12"
        >
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-if="model.type_afiliate === 'afiliate'"
                v-model="model.afiliado_id"
                :items="afiliates"
                :label="$t('lbl.afiliateCorporate')"
                outlined
                dense
                hide-details
                item-text="name_comercial"
                item-value="id"
                @change="setAfiliate"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.afiliateCorporate') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
              <v-select
                v-if="model.type_afiliate === 'propietario'"
                v-model="model.type_price"
                :items="prices"
                :label="$t('menu.dataGeneral')"
                outlined
                dense
                hide-details
                item-text="name"
                item-value="slug"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('menu.dataGeneral') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <!--v-if="sale_product === 'cars'"-->
            <v-col
              cols="12"
              md="4"
            >
              <v-radio-group
                v-model="model.type_afiliate"
                class="mt-0 pt-0"
                row
              >
                <v-radio
                  :label="$t('lbl.afiliateCorporate')"
                  value="afiliate"
                  @click="setTypeSale('afiliate')"
                ></v-radio>
                <v-radio
                  :label="$t('menu.dataGeneral')"
                  value="propietario"
                  @click="setTypeSale('propietario')"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-spacer></v-spacer>
            <v-col
              cols="12"
              md="2"
              class="text-center"
            >
              <v-btn
                color="primary"
                outlined
                :disabled="itemsTarifariosCheck.length === 0"
                @click="send"
              >
                <v-icon class="mr-2">
                  {{ icons.mdiSendOutline }}
                </v-icon>
                {{ $t('btn.send') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
              class="text-center"
            >
              <v-btn
                color="primary"
                outlined
                :loading="loading"
                :disabled="itemsTarifariosCheck.length === 0"
                @click="actionMenu('download')"
              >
                <v-icon class="mr-2">
                  mdi-file-pdf-box
                </v-icon>
                {{ $t('btn.dowload') }}
              </v-btn>
              <!--<v-row no-gutters>
                <v-col
                  cols="12"
                  md="10"
                >
                  <v-overflow-btn
                    :items="btnPrint"
                    :label="$t('btn.print')"
                    color="primary"
                    outlined
                    dense
                    :prepend-inner-icon="icons.mdiPrinter"
                    :loading="loading"
                    hide-details
                    :disabled="itemsTarifarios.length === 0"
                  >
                    <template v-slot:item="{ item }">
                      <v-icon left>
                        {{ item.icon }}
                      </v-icon>
                      <v-list-item-content @click="actionMenu(item.slug)">
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <template v-slot:selection="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.text }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-overflow-btn>
                </v-col>
              </v-row>-->
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <v-tabs
      v-if="model.afiliado_id || model.type_price"
      v-model="currentTab"
    >
      <!--<v-tab
        v-if="productsPermit.includes('vuelos')"
        @click="setSaleProduct('vuelos')"
      >
        <v-tooltip
          top
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiAirplane }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('menu.flights') }}</span>
        </v-tooltip>
      </v-tab>-->
      <v-tab
        v-if="productsPermit.includes('cars')"
        @click="setSaleProduct('cars')"
      >
        <v-tooltip
          top
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiCar }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('menu.cars') }}</span>
        </v-tooltip>
      </v-tab>
      <v-tab
        v-if="productsPermit.includes('hotels')"
        @click="setSaleProduct('hotels')"
      >
        <v-tooltip
          top
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiOfficeBuilding }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('menu.hotels') }}</span>
        </v-tooltip>
      </v-tab>
    </v-tabs>

    <v-divider></v-divider>

    <v-tabs-items
      v-if="model.afiliado_id || model.type_price"
      v-model="currentTab"
    >
      <!--<v-tab-item v-if="productsPermit.includes('vuelos')">
      </v-tab-item>-->
      <v-tab-item v-if="productsPermit.includes('cars')">
        <CarsTab
          :user="user"
          :model="model"
          :proveedores="proveedoresCar"
          :transmisiones="transmisiones"
          :combustibles="combustibles"
          :contratos="contratesCar"
          :operadores="operadoresCar"
          @clearSearch="clearSearch"
        />
      </v-tab-item>
      <v-tab-item v-if="productsPermit.includes('hotels')">
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <HotelsTab
              :user="user"
              :model="model"
              :proveedores="proveedoresHotel"
              :operadores="operadoresHotel"
              :contratos="contratesHotel"
              :tipo-destino="tipoDestino"
              :hotels="hotels"
              @clearSearch="clearSearch"
            />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>

    <SidebarSend
      v-if="isSidebarSendActive"
      v-model="isSidebarSendActive"
      :name-client="model.afiliado_id ? afiliates.filter(e => e.id === model.afiliado_id)[0].name_comercial : ''"
      :user="user"
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyePlus,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiFormatListText,
  mdiEraserVariant,
  mdiArrowUp,
  mdiArrowDown,
  mdiMenuDown,
  mdiMenuRight,
  mdiAirplane,
  mdiCar,
  mdiOfficeBuilding,
  mdiPrinter,
  mdiSendOutline,
} from '@mdi/js'

import SidebarSend from '../utils/SidebarSend.vue'

import FlightsTab from '../tabs/Flights.vue'
import CarsTab from '../tabs/Cars.vue'
import HotelsTab from '../tabs/Hotels.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    FlightsTab,
    CarsTab,
    // eslint-disable-next-line vue/no-unused-components
    HotelsTab,
    SidebarSend,
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()

    return {
      menuIsVerticalNavMini,
    }
  },
  data() {
    return {
      isSidebarSendActive: false,
      nameb2b: null,
      name_project: process.env.VUE_APP_PROJ_NAME,
      btnPrint: [
        { text: 'PDF', icon: 'mdi-file-pdf-box', slug: 'pdf' },
        { text: 'XLS', icon: 'mdi-file-excel', slug: 'xls' },
        { text: this.$t('btn.print'), icon: 'mdi-printer', slug: 'print' },
      ],
      isLoading: true,
      loading: false,
      currentTab: 0,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyePlus,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiFormatListText,
        mdiEraserVariant,
        mdiArrowUp,
        mdiArrowDown,
        mdiMenuDown,
        mdiMenuRight,
        mdiAirplane,
        mdiCar,
        mdiOfficeBuilding,
        mdiPrinter,
        mdiSendOutline,
      },
      itemsPerPage: 10,
      permisos: sessionStorage.getItem('permisos_auth'),
      model: {
        type_afiliate: 'afiliate',
        type_price: null,
        afiliado_id: 0,
        proveedor_id: 0,
        destino: 'Todos',
        dateIn: null,
        dateOut: null,
        operador_id: 0,
        tipo_destino_id: 0,
        hotels: [],
        season_id: 0,
        transmision_id: 0,
        combustible_id: 0,
        contrate_id: 0,
        recogida_id: null,
      },
      sale_product: 'cars',
      afiliates: [],
      prices: [],
      minDate: Date.now(),
      maxDate: Date.now(),
      menuFrom: false,
      menuTo: false,
      showTabs: true,
      items: [],
      user: {},
      productsPermit: [],
      proveedoresHotel: [],
      contratesHotel: [],
      tipoDestino: [],
      operadoresHotel: [],
      hotels: [],
      proveedoresCar: [],
      seasonsCar: [],
      transmisiones: [],
      combustibles: [],
      contratesCar: [],
      operadoresCar: [],
    }
  },
  computed: {
    ...mapState({
      itemsTarifarios: state => state.app.itemsTarifarios,
    }),
    itemsTarifariosCheck() {
      const arr = []
      this.itemsTarifarios.forEach(element => {
        if (element.check) {
          arr.push(element)
        }
      })

      return arr
    },
  },
  mounted() {
    // PARA Q NAZCA EL MENU IZQ OCULTO
    this.menuIsVerticalNavMini = true
    this.profile()
  },
  created() {
    this.getAfiliados()

    if (this.permisos.includes('tarifario_neto:list')) {
      this.prices.push({
        name: 'Neto',
        slug: 'neto',
      })
    }
    this.prices.push({
      name: this.$t('lbl.saleOnline'),
      slug: 'online',
    })
    this.prices.push({
      name: this.$t('lbl.saleFisic'),
      slug: 'fisic',
    })

    // CARS
    this.getProveedoresCar()
    this.getTransmisiones()
    this.getCombustibles()
    this.getContratesCar()
    this.getRentadoras()

    // HOTELS
    this.getProveedoresHotel()
    this.getOperadoresHotel()
    this.getContratesHotel()
    this.getTipoDestino()
    this.getHoteles()
  },
  methods: {
    ...mapMutations(['updateMarkupsList', 'deleteMarkupsList', 'updateItemsTarifarios', 'updateSearchCars']),
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          }
          this.user = response.data.data
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.getProductsPermit()
        })
    },
    actionMenu(slug) {
      this.loading = true

      const json = {
        action: slug,
        items: this.itemsTarifariosCheck,
      }
      if (slug === 'download') {
        this.axios
          .post('tarifarios/hotels/action', json, {
            responseType: 'arraybuffer',
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            // console.log(res)
            const filename = 'Tarifario'

            this.downloadFile(res, filename)
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            this.loading = false
          })
      }
    },
    downloadFile(response, filename) {
      const newBlob = new Blob([response.data], { type: 'application/pdf' })

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob)

        return
      }

      const data = window.URL.createObjectURL(newBlob)

      const link = document.createElement('a')

      link.href = data
      link.download = `${filename}.pdf`
      link.click()
      setTimeout(() => {
        window.URL.revokeObjectURL(data)
      }, 100)
    },

    getProductsPermit() {
      this.axios
        .get('type_product?per_page=1000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          const result = res.data.data
          if (this.user.company_id) {
            if (this.user.company.products.length > 0) {
              result.forEach(element => {
                if (this.user.company.products.includes(element.slug)) {
                  this.productsPermit.push(element.slug)
                }
              })
            }
          } else {
            result.forEach(element => {
              this.productsPermit.push(element.slug)
            })
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getAfiliados() {
      this.axios
        .post(
          'afiliados/get-clients-corporate',
          { afiliatesCorporatives: true },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          this.afiliates = res.data.data.clients
        })
    },

    getProveedoresHotel() {
      this.axios
        .post('proveedors/byproduct', { slug: 'hotels', only_contrate: true }, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          const result = res.data.data
          this.proveedoresHotel.push({
            name_comercial: 'Todos',
            id: 0,
          })
          result.forEach(element => {
            this.proveedoresHotel.push(element)
          })
        })
    },
    getOperadoresHotel() {
      this.axios
        .get(`nom_operador_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          const result = res.data.data
          this.operadoresHotel.push({
            name: 'Todos',
            id: 0,
          })
          result.forEach(element => {
            this.operadoresHotel.push(element)
          })
        })
    },
    getContratesHotel() {
      const json = {
        per_page: 10000,
        only_active: true,
      }
      this.axios
        .post('contrate_hotels/index', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            const result = res.data.data
            this.contratesHotel.push({
              identificador: 'Todos',
              id: 0,
              operadorsId: [],
            })
            result.forEach(element => {
              this.contratesHotel.push(element)
            })
          }
        })
    },
    getTipoDestino() {
      this.axios
        .get(`nom_tipo_destino_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          const result = res.data.data
          this.tipoDestino.push({
            name: 'Todos',
            id: 0,
          })
          result.forEach(element => {
            this.tipoDestino.push(element)
          })
        })
    },
    getHoteles() {
      const json = {
        page: 1,
        per_page: 100000,
      }
      this.axios
        .post('hotels/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.hotels = res.data.data
          }
        })
    },

    getProveedoresCar() {
      this.axios
        .post('proveedors/byproduct', { slug: 'cars', only_contrate: true }, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          const result = res.data.data
          this.proveedoresCar.push({
            name_comercial: 'Todos',
            id: 0,
          })
          result.forEach(element => {
            this.proveedoresCar.push(element)
          })
        })
    },
    getTransmisiones() {
      this.axios
        .get('nom_transmision_cars?per_page=1000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          const result = res.data.data
          this.transmisiones.push({
            name: 'Todas',
            id: 0,
          })
          result.forEach(element => {
            this.transmisiones.push(element)
          })
        })
    },
    getCombustibles() {
      this.axios
        .get(`nom_combustible_cars?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          const result = res.data.data
          this.combustibles.push({
            name: 'Todos',
            id: 0,
          })
          result.forEach(element => {
            this.combustibles.push(element)
          })
        })
    },
    getContratesCar() {
      const json = {
        per_page: 10000,
        only_active: true,
      }
      this.axios
        .post('contrate_cars/index', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            const result = res.data.data
            this.contratesCar.push({
              identificador: 'Todos',
              id: 0,
            })
            result.forEach(element => {
              this.contratesCar.push(element)
            })
          }
        })
    },
    getRentadoras() {
      this.axios
        .get('nom_rentadora_cars?per_page=1000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          const result = res.data.data
          this.operadoresCar.push({
            name: 'Todos',
            id: 0,
          })
          result.forEach(element => {
            this.operadoresCar.push(element)
          })
        })
    },

    clearSearch() {
      this.model = {
        ...this.model,
        proveedor_id: 0,
        destino: 'Todos',
        dateIn: null,
        dateOut: null,
        tipo_destino_id: 0,
        operador_id: 0,
        hotels: [],
        season_id: 0,
        transmision_id: 0,
        combustible_id: 0,
        contrate_id: 0,
        recogida_id: null,
      }
      this.updateItemsTarifarios([])
      this.updateSearchCars(false)
    },

    setAfiliate() {
      this.updateSearchCars(false)
      this.updateItemsTarifarios([])
    },

    setSaleProduct(product) {
      this.sale_product = product
      this.updateItemsTarifarios([])
    },
    setTypeSale(type) {
      if (type === 'propietario') {
        this.model.afiliado_id = null
      } else {
        this.model.type_price = null
      }
      this.updateSearchCars(false)
      this.updateItemsTarifarios([])
    },

    send() {
      this.isSidebarSendActive = true
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
